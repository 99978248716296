var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"position-absolute barra",attrs:{"cols":"8"}},[_c('b-progress',{attrs:{"value":_vm.valueBar,"max":"100","variant":"success","animated":""}})],1)],1),_c('b-row',{staticClass:"justify-content-around position-relative zindex-4"},[_c('b-button',{staticClass:"font-large-2 font-weight-bolder padding-boton-1 circulo",class:{
                        'animate__animated animate__flip': _vm.selected === 1,
                    },attrs:{"variant":((_vm.selected >= 1
                            ? 'outline-success'
                            : 'outline-primary') + " bg-white")},on:{"click":function($event){_vm.selected = 1}}},[_c('div',{staticClass:"row"},[_c('feather-icon',{attrs:{"icon":"MonitorIcon","size":"45"}})],1)]),_c('b-button',{staticClass:"font-large-2 circulo",class:{
                        'animate__animated animate__flip': _vm.selected === 2,
                    },attrs:{"variant":((_vm.selected >= 2
                            ? 'outline-success'
                            : 'outline-primary') + " bg-white")},on:{"click":function($event){_vm.selected = 2}}},[_c('div',{staticClass:"row px-25"},[_c('feather-icon',{attrs:{"icon":"ActivityIcon","size":"45"}})],1)]),_c('b-button',{staticClass:"font-large-2 circulo",class:{
                        'animate__animated animate__flip': _vm.selected === 3,
                    },attrs:{"variant":((_vm.selected >= 3
                            ? 'outline-success'
                            : 'outline-primary') + " bg-white")},on:{"click":function($event){_vm.selected = 3}}},[_c('div',{staticClass:"row px-25"},[_c('feather-icon',{attrs:{"icon":"ClipboardIcon","size":"45"}})],1)])],1)],1)],1),_c('div',{staticClass:"contador d-flex"},[_c('feather-icon',{staticClass:"text-light",attrs:{"icon":"ClockIcon","size":"50"}}),_c('div',{staticClass:"hora rounded mt-50 text-light font-large-1 font-weight-bolder text-center animate__animated animate__bounceInRight"},[_vm._v(" "+_vm._s(_vm.horas)+":"+_vm._s(_vm.minutos)+":"+_vm._s(_vm.segundos)+" ")])],1),_c('transition',{attrs:{"name":"slide","mode":"out-in","enter-class":_vm.selected > _vm.selectedFrom
                ? 'slide-enter-right'
                : 'slide-enter-left',"enter-to-class":_vm.selected > _vm.selectedFrom
                ? 'slide-enter-to-right'
                : 'slide-enter-to-left',"leave-class":_vm.selected > _vm.selectedFrom
                ? 'slide-leave-right'
                : 'slide-leave-left',"leave-to-class":_vm.selected > _vm.selectedFrom
                ? 'slide-leave-to-right'
                : 'slide-leave-to-left'}},[_c('keep-alive',[_c(_vm.etapaSelected,{key:_vm.etapaSelected,tag:"component",on:{"iniciarAsistencia":_vm.iniciarAsistencia}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }