<template>
    <div>
        <b-row>
            <b-col>
                <b-row>
                    <b-col cols="8" class="position-absolute barra">
                        <b-progress
                            :value="valueBar"
                            max="100"
                            variant="success"
                            animated
                        >
                        </b-progress>
                    </b-col>
                </b-row>
                <b-row
                    class="justify-content-around position-relative zindex-4"
                >
                    <b-button
                        :variant="`${
                            selected >= 1
                                ? 'outline-success'
                                : 'outline-primary'
                        } bg-white`"
                        class="font-large-2 font-weight-bolder padding-boton-1 circulo"
                        :class="{
                            'animate__animated animate__flip': selected === 1,
                        }"
                        @click="selected = 1"
                    >
                        <div class="row">
                            <feather-icon
                                icon="MonitorIcon"
                                size="45"
                            ></feather-icon>
                        </div>
                    </b-button>
                    <b-button
                        :variant="`${
                            selected >= 2
                                ? 'outline-success'
                                : 'outline-primary'
                        } bg-white`"
                        class="font-large-2 circulo"
                        :class="{
                            'animate__animated animate__flip': selected === 2,
                        }"
                        @click="selected = 2"
                    >
                        <div class="row px-25">
                            <feather-icon
                                icon="ActivityIcon"
                                size="45"
                            ></feather-icon>
                        </div>
                    </b-button>
                    <b-button
                        :variant="`${
                            selected >= 3
                                ? 'outline-success'
                                : 'outline-primary'
                        } bg-white`"
                        class="font-large-2 circulo"
                        :class="{
                            'animate__animated animate__flip': selected === 3,
                        }"
                        @click="selected = 3"
                    >
                        <div class="row px-25">
                            <feather-icon
                                icon="ClipboardIcon"
                                size="45"
                            ></feather-icon>
                        </div>
                    </b-button>
                </b-row>
            </b-col>
        </b-row>
        <div class="contador d-flex">
            <feather-icon icon="ClockIcon" size="50" class="text-light">
            </feather-icon>
            <div
                class="hora rounded mt-50 text-light font-large-1 font-weight-bolder text-center animate__animated animate__bounceInRight"
            >
                {{ horas }}:{{ minutos }}:{{ segundos }}
            </div>
        </div>
        <transition
            name="slide"
            mode="out-in"
            :enter-class="
                selected > selectedFrom
                    ? 'slide-enter-right'
                    : 'slide-enter-left'
            "
            :enter-to-class="
                selected > selectedFrom
                    ? 'slide-enter-to-right'
                    : 'slide-enter-to-left'
            "
            :leave-class="
                selected > selectedFrom
                    ? 'slide-leave-right'
                    : 'slide-leave-left'
            "
            :leave-to-class="
                selected > selectedFrom
                    ? 'slide-leave-to-right'
                    : 'slide-leave-to-left'
            "
        >
            <keep-alive>
                <component
                    :is="etapaSelected"
                    :key="etapaSelected"
                    @iniciarAsistencia="iniciarAsistencia"
                />
            </keep-alive>
        </transition>
    </div>
</template>
<script>
import moment from "moment";
import { ref, onMounted, computed, watch } from "@vue/composition-api";

export default {
    components: {
        Stage: () => import("./etapas/Stage.vue"),
        Evaluacion: () => import("./etapas/Evaluacion.vue"),
        Seguimiento: () => import("./etapas/Seguimiento.vue"),
    },
    setup(props) {
        const selectedFrom = ref(0);
        const selected = ref(1);

        const valueBar = computed(() => {
            if (selected.value == 1) return 24;
            if (selected.value == 2) return 75;
            if (selected.value == 3) return 100;
        });

        const etapaSelected = computed(() => {
            if (selected.value == 1) return "stage";
            if (selected.value == 2) return "evaluacion";
            if (selected.value == 3) return "seguimiento";
        });

        // para el timer
        const horas = ref(0);
        const minutos = ref(0);
        const segundos = ref(0);

        watch(
            selected,
            (newValue, oldValue) => (selectedFrom.value = oldValue)
        );

        onMounted(() => {
            startLoopReloj();
        });

        function startLoopReloj() {
            const fechaInicioAsistencia = ref(moment());

            let interval = setInterval(() => {
                let now = moment();

                let tiempo = moment.duration(
                    now.diff(fechaInicioAsistencia.value, "seconds"),
                    "seconds"
                );

                horas.value = tiempo.get("hours");
                minutos.value = tiempo.get("minutes");
                segundos.value = tiempo.get("seconds");
            }, 1000);
        }

        function iniciarAsistencia() {
            selected.value = 2;
        }

        return {
            selected,
            valueBar,
            etapaSelected,
            selectedFrom,
            iniciarAsistencia,
            horas,
            minutos,
            segundos,
        };
    },
};
</script>
<style lang="css" scope>
.circulo {
    border-radius: 50%;
}
.barra {
    margin-left: auto;
    margin-right: auto;
    top: 40%;
    left: 0;
    right: 0;
    text-align: center;
}

.contador {
    height: 50px;
    width: 50px;
    background-color: rgba(20, 20, 20, 0.3);
    border-radius: 50%;
    position: fixed;
    right: 1%;
    z-index: 50;
    transition: all 400ms ease-in-out;
}

.hora {
    display: none;
    height: 40px;
    width: 120px;
    background-color: rgba(20, 20, 20, 0.3);
    position: fixed;
    right: 80px;
    z-index: 50;
}

.contador:hover .hora,
.contador:hover {
    background-color: rgba(20, 20, 20, 0.6);
    display: block;
}

.padding-boton-1 {
    padding: 0px 25px;
}
/*cuando selected > selectedFrom*/
.slide-enter-right {
    transform: translateX(110%);
}
.slide-enter-to-right {
    transform: translateX(0);
}

.slide-leave-right {
    transform: translateX(0);
}
.slide-leave-to-right {
    transform: translateX(-110%);
}

/*cuando selected < selectedFrom*/

.slide-enter-left {
    transform: translateX(-110%);
}
.slide-enter-to-left {
    transform: translateX(0);
}

.slide-leave-left {
    transform: translateX(0);
}
.slide-leave-to-left {
    transform: translateX(110%);
}

.slide-enter-active,
.slide-leave-active {
    transition: all 400ms ease-in-out;
}
</style>
